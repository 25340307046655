.parent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media only Screen and (max-width: 40em) {
    .parent {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      /* overflow-x: hidden;
      overflow-y: auto; */
    }
  }